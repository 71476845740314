var galleryInserts = document.querySelectorAll('div.galleryinsert');
if (galleryInserts.length > 0) {
    var _loop_1 = function (i) {
        galleryInserts[i].setAttribute('style', '');
        var galleryConfigTmp = galleryInserts[i].innerHTML.split('=')[1].split('|');
        galleryConfigTmp[galleryConfigTmp.length - 1] = galleryConfigTmp[galleryConfigTmp.length - 1].slice(0, -1);
        var galleryConfig = {
            id: galleryConfigTmp[0],
            show_title: galleryConfigTmp[1],
            show_desc: galleryConfigTmp[2],
            img_count: galleryConfigTmp[3]
        };
        fetch('/ajaxNoTracy/getGalleryById/' +
            galleryConfig.id + '/' +
            galleryConfig.show_title + '/' +
            galleryConfig.show_desc + '/' +
            galleryConfig.img_count, {
            method: 'GET'
        })
            .then(function (response) {
            if (response.ok) {
                return response.json();
            }
            throw new Error('Error on post');
        })
            .then(function (data) {
            galleryInserts[i].innerHTML = data;
        })["catch"](function (error) {
            console.log(error);
            return;
        });
    };
    for (var i = 0; i < galleryInserts.length; i++) {
        _loop_1(i);
    }
}
